import React, { useState } from "react";
import first from "../../assets/images/first2.png";
import T from "../../assets/images/T.svg";
import archive from "../../assets/images/archive.svg";
import GPTQuestions from "../GPTQuestions";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useTrivia } from "../../contexts/TriviaContext";
import MyTrivia from "../MyTrivia";
import useTriviaQuestionCreator from "../../customHooks/useTriviaQuestionCreator";
import { useFeud } from "../../contexts/FeudContext";
// import { API_KEY, API_USER_UPLOAD_QUESTION } from "../constants/routes";
import { mains } from "../constants/routes";
import event from "../../customHooks/useGoogleAnalyticsTrigger";
import { renderRadioButtonCollection } from "../common/renderCollections";

function parseTriviaData(input) {
  // Split the input by the [Q] marker
  const questionBlocks = input
    .split(/\[Q\]/)
    .filter((block) => block.trim() !== "");

  const questions = questionBlocks.map((block, index) => {
    // Split block into lines, trim and filter empty lines
    const lines = block
      .trim()
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== "");

    // The first line is the question
    const questionText = lines[0].trim();

    // Initialize an array to store answers and their points
    const answerPoints = [];

    // Loop through the remaining lines to extract answers and points
    lines.slice(1).forEach((line) => {
      // Use regex to match answer text and points in ((XX)) format
      const match = line.match(/^(.*?)\s*\(\((\d+)\)\)$/);
      if (match) {
        const answerText = match[1].trim(); // Answer before the points
        const points = parseInt(match[2].trim()); // Points inside (( ))
        answerPoints.push({ key: answerText, value: points });
      }
    });

    // Return a question object
    return {
      description: questionText, // Question text
      answers: answerPoints.map((answer) => answer.key), // Answer text list
      answerPoints: answerPoints, // Answer text with corresponding points
      imageUrl: "", // Placeholder for future image support
      index: index + 1, // Question index for uniqueness
      open: false, // Default UI flag
    };
  });

  return questions;
}


function parseTrivia(text) {
  const blocks = text.trim().split("\n\n");
  const questions = blocks.map((block) => {
    const lines = block.split("\n");
    const questionText = lines[0];
    const options = lines
      .slice(1, -1)
      .map((option) => option.substring(3).trim()); // Remove the option prefixes like 'a) '
    const answerLine = lines[lines.length - 1];
    const answer = answerLine.split(": ")[1].trim(); // Get the full answer 'b) ran'
    const answerLetter = answer[0]; // Extract just the letter 'b'

    return {
      question: questionText,
      options: options,
      answer: answerLetter, // Return just the letter
    };
  });

  return questions;
}

export default function Index() {
  let gameTypeRef = React.useRef("type_choice");
  const [active, setActive] = useState("one");
  const [onNext, setOnNext] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Initialize state
  const [messageApi, contextHolder] = message.useMessage();
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [triviaQuestion, setCustomTriviaQuestions] = useState([]);
  const [newCustomData, setNewCustomData] = useState([]);
  const privecyTypeRef = React.useRef("access_public");
  const [editID, setEditId] = useState(null);
  const [gameType, setGameType] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [element_ID, setElementId] = useState("");

  const [editing, setEditing] = useState(false);

  // None State Actors
  const triviaID = React.useRef();

  const {
    handleAdditionFromText,
    editGlobalTrivaquestions,
    triviaQuestionCache,
  } = useTrivia();
  const {
    feudQuestionCache,
    editGlobalFeaudquestions,
    handleAdditionFromTextFeud,
  } = useFeud();

  const [categorys, setCategorys] = useState([
    "Fiction",
    "Superman",
    "Sci-Fi",
    "Education",
  ]);
  const { createQuestionWithData, updateQuestionWithData } =
    useTriviaQuestionCreator();

  // Custom Question Adding or setting data
  const createCustomHandQuestions = async (data) => {
    await setNewCustomData(data);
  };

  const createQuestionWithDatas = async (data) => {
    await setCustomTriviaQuestions(data);
  };

  const addToCategory = (e) => {
    setCategory(e.target.value);
  };
  const addToList = () => {
    if (category === "") {
      setValidationError(true);
      // alert('empity category field')
    } else {
      setSelectedCategory([...selectedCategory, category]);
    }
  };

  const selectCateogyr = (cateogry) => {
    if (selectedCategory.includes(cateogry)) {
    } else {
      setSelectedCategory((prev) => [...prev, cateogry]);
    }
  };

  const removeCategory = (name) => {
    let newSelectedCategory = selectedCategory.filter((c) => c != name);
    setSelectedCategory(newSelectedCategory);
  };

  const info = () => {
    messageApi.error("Please Use the appropriate format");
  };

  const handleChange = (event) => {
    setInputValue(event.target.value); // Update state with new input
  };

  const setActiveTab = (value) => {
    setActive(value);
  };
  const onNextChange = async (e) => {
    gameTypeRef.current = e;
    await setOnNext(!onNext);
  };

  // function drilling to edit in my trivia
  // this function sets all the states to allow viewing
  // on the frontend
  const onEditChange = async (e, game) => {
    let main = await mains();
    if (e == "") {
      setActive("one");
      return;
    }

    setEditing(true);
    const url = main.API_USER_UPLOAD_QUESTION + `/${e.data.dataId}`;
    const options = {
      method: "GET",
      headers: {
        "api-key": main.API_KEY,
        Accept: "application/json",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setTitle(e.data.name);
      setDescription(e?.data?.description);
      setEditId(e.data?.dataId);
      setElementId(e?.element_id);
      setSelectedCategory(e?.data?.tags);

      const gameType = e?.data?.tags.includes("type_choice")
        ? "type_choice"
        : "type_feud";
      let gameVisibility = e?.data?.tags.filter((item) => {
        return item == "access_private" || item == "access_public";
      });
      setGameType(gameType);
      privecyTypeRef.current = gameVisibility.length
        ? gameVisibility[0]
        : "access_public";
      gameTypeRef.current = gameType;

      if (gameType === "type_choice") {
        editGlobalTrivaquestions(data.data.questions);
      } else {
        editGlobalFeaudquestions(data.data.questions);
      }
      setOnNext(true);
      setActive("one");
    } catch (error) {
      console.error(error);
    }
  };

  const convert = async (text) => {
    // parseTriviaData
    let texts = null;
    if (gameTypeRef.current === "type_choice") {
      try {
        texts = await parseTrivia(text);

        let temp = [];

        texts.map((text, index) => {
          // Extract the answer letter and format the answer
          let answerMatch = text.answer.match(/Answer:\s([a-d])\)/i);
          let formattedAnswer = answerMatch
            ? `Answer: ${answerMatch[1]})`
            : text.answer;

          let arch = {
            id: index + 1,
            question: text.question,
            options: [],
            answer: formattedAnswer,
            open: false,
          };

          text.options.map((item) => {
            arch.options.push(item);
          });

          temp.push(arch);
        });

        handleAdditionFromText(temp);
        setActive("one");
      } catch (error) {
        console.log(error);
        info();
      }
    } else {
      try {
        texts = await parseTriviaData(text);
        console.log(texts);

        handleAdditionFromTextFeud(texts);
        setActive("one");

      } catch (error) {

        console.log(error)
      }
    }
  };

  const gameTypeChangeDataCheckForm = (e) => {
    setGameType(e.target.value);
    gameTypeRef.current = e.target.value;
  };

  const gameTypeChangeDataCheck = (e) => {
    console.log("data", e);
    setGameType(e);
    gameTypeRef.current = e;
  };
  const privecyTypeChangeDataCheck = (e) => {
    console.log("main", e);
    privecyTypeRef.current = e;
  };
  // create game
  const CreateAnewGame = async (
    title,
    selectedCategory,
    description,
    gameType,
    triviaQuestion,
    feudQuestionCache,
    triviaID,
    privacy
  ) => {
    event({
      action: "Upload",
      category: gameType,
      label: `Upload ${gameType}`,
      value: title,
    });

    let data = await createQuestionWithData(
      title,
      selectedCategory,
      description,
      gameType,
      triviaQuestion,
      feudQuestionCache,
      triviaID,
      privacy
    );

    if (data == 1) {
      messageApi.success("Created");
    } else {
      messageApi.error("Failed to create");
    }
  };

  const UpdateGameQuestion = async (
    editID,
    triviaQuestionCache,
    element_ID,
    selectedCategory,
    title,
    gameType,
    description,
    feudQuestionCache,
    privacy
  ) => {
    event({
      action: "Edit",
      category: gameType,
      label: `Edit ${gameType}`,
      value: editID,
    });
    let data = await updateQuestionWithData(
      editID,
      triviaQuestionCache,
      element_ID,
      selectedCategory,
      title,
      gameType,
      description,
      feudQuestionCache,
      privacy
    );
    if (data == 1) {
      messageApi.success("Updated");
    } else {
      messageApi.error(" Failed to Update");
    }
  };

  const placeholderChange = (item) =>{
    if (item == 'type_choice'){
      return `Enter question and options like:\n1. What is the past tense of 'run'?\na) runned\nb) ran\nc) runned\nd) rung\nAnswer: b) ran \n\nQuestion Number and Option Latter are not required`
    } else if (item == 'type_feud'){
      return `[Q] ቁርስ ላይ ሊበላ የሚችል የኢትዪጵያ ምግብ ጥቀሱ\nጨጨብሳ | Chechebsa ((100))\nቡላ | Bula ((90))\nቅንጬ | ቂንጬ | Kinche | Kenche | Qinche | Qenche ((80))\nገንፎ | Genfo ((70))\nፍርፍር | Firfir ((60))\nቃተኛ | Kategna ((50))\nጭኮ | Chiko ((40))\nአምባሻ | Ambasha ((30))`
    } else {
      return `[Q] ቁርስ ላይ ሊበላ የሚችል የኢትዪጵያ ምግብ ጥቀሱ\nጨጨብሳ | Chechebsa ((100))`
    }
  }

  return (
    <div className="prompt-cont">
      {contextHolder}
      <div className="">
        <div className="flex flex-row">
          {/* toggle  */}
          <div className="flex flex-col border-r-[0.5px] border-[#3B3F46] min-h-[85vh] w-[10%] md:w-[4%]  pt-10 ">
            <div
              onClick={() => setActiveTab("one")}
              className={`flex flex-row justify-center py-2 ${
                active == "one" ? "bg-[#414550]" : null
              } w-[100%] cursor-pointer`}
            >
              <img className="w-5" src={first} />
            </div>
            <br />
            <div
              onClick={() => setActiveTab("two")}
              className={`flex flex-row justify-center py-2 ${
                active == "two" ? "bg-[#414550]" : null
              } w-[100%] cursor-pointer`}
            >
              <img className="w-5" src={T} />
            </div>
            <div
              onClick={() => setActiveTab("three")}
              className={`flex flex-row justify-center py-2 mt-5 ${
                active == "three" ? "bg-[#414550]" : null
              } w-[100%] cursor-pointer`}
            >
              <img className="w-5" src={archive} />
            </div>
          </div>
          {["Data Exists"].map((_, __) => {
            if (active == "one") {
              return (
                <div className="flex flex-col md:flex-row w-[100%]">
                  <div className="w-[100%] md:w-[50%]  bg-[#1D1E23]">
                    <GPTQuestions
                      createQuestionWithData={createQuestionWithDatas}
                      createCustomHandQuestions={createCustomHandQuestions}
                      onNext={onNextChange}
                      gameTypeRef={gameTypeRef}
                      gameTypeChangeData={gameTypeChangeDataCheck}
                      // privacy
                      privecyTypeRef={privecyTypeRef}
                      privecyTypeChangData={privecyTypeChangeDataCheck}
                    />
                  </div>
                  {/* category form */}
                  <div className="w-[100%] min-h-[100vh] md:h-[auto] md:w-[50%]">
                    {onNext ? (
                      <div className="md:fixed flex flex-col justify-center self-center items-center text-white  h-[100%] -mt-10">
                        <div className="flex flex-col w-[100%] items-center justify-center self-center">
                          <input
                            value={title}
                            type="text"
                            className=" bg-[#2E3340] px-10 py-3 w-[80%] text-white rounded-md"
                            placeholder="Add Title Here"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-[80%] mt-10 px-5 py-3 bg-[#2E3340] rounded-md h-40"
                            placeholder="Add description here"
                          />
                        </div>
                        <div className="mt-5">
                          <input
                            placeholder="Add Custom Category"
                            onChange={(e) => addToCategory(e)}
                            type="text"
                            error={validationError}
                            required={true}
                            value={category}
                            className="bg-[#2E3340] outline-none rounded-md text-xs py-2 px-2"
                          />
                          <a
                            onClick={() => addToList()}
                            className="bg-[#2E3340] px-2 py-2 rounded-md ml-5"
                          >
                            <PlusOutlined />
                          </a>
                        </div>
                        <div className="flex flex-row overflow-hidden pt-5 mt-0  flex-wrap w-[80%] ">
                          {selectedCategory.map((item, index) => (
                            <div
                              onClick={() => removeCategory(item)}
                              key={index}
                              className="px-5 flex flex-row mr-2 my-1 rounded-md text-[gray] py-3 bg-[#2E3340] text-sm"
                            >
                              <div className="text-xs">{item}</div>
                              <div className="ml-4 rounded-xl w-5 h-5  cursor-pointer text-center text-white ">
                                <CloseCircleOutlined />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row overflow-hidden pt-5 mt-2  flex-wrap w-[80%] border-t-[1px]">
                          {categorys.map((item, index) => (
                            <div
                              onClick={() => selectCateogyr(item)}
                              key={index}
                              className="px-5 flex flex-row mr-2 my-1 rounded-md text-[gray] py-1 bg-[#2E3340] text-sm"
                            >
                              <div className="text-xs">{item}</div>
                              <div className="ml-4 rounded-xl w-5 h-5  cursor-pointer text-center text-white ">
                                <PlusOutlined />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="mt-5">
                          {editing ? (
                            <button
                              onClick={() =>
                                UpdateGameQuestion(
                                  editID,
                                  triviaQuestionCache,
                                  element_ID,
                                  selectedCategory,
                                  title,
                                  gameType,
                                  description,
                                  feudQuestionCache,
                                  privecyTypeRef.current
                                )
                              }
                              className="border-[#6904E9] border-[1px] px-5 py-1 rounded-md uppercase ml-5 text-xs"
                            >
                              Edit Trivia
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                CreateAnewGame(
                                  title,
                                  selectedCategory,
                                  description,
                                  gameTypeRef.current,
                                  triviaQuestion,
                                  feudQuestionCache,
                                  triviaID,
                                  privecyTypeRef.current
                                )
                              }
                              className="bg-[#6904E9] px-5 py-1 rounded-sm uppercase text-xs"
                            >
                              Upload
                            </button>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            } else if (active == "two") {
              return (
                <div className="flex flex-row justify-between w-[100%] ">
                  <div className="w-[90%] md:w-[45%] h-[100%] pt-8">
                    <div className="w-[100%] border-b-[1px] border-black px-5 py-2 border-t-[1px]">
                      {renderRadioButtonCollection(
                        gameTypeRef.current,
                        gameTypeChangeDataCheckForm
                      )}
                    </div>
                    <textarea
                      value={inputValue}
                      onChange={handleChange}
                      className="w-[100%] h-[80%] p-10 bg-[#2E3340] rounded-xl outline text-white m-5"
                      placeholder={placeholderChange(gameTypeRef.current)}
                      rows="10"
                      cols="50"
                    />
                    <div className="flex flex-row justify-between mx-10 w-[100%]">
                      <button
                        onClick={() => convert(inputValue)}
                        className="mx-[45%] bg-[#2E3340] text-white px-5 py-2"
                      >
                        Convert
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else if (active == "three") {
              return <MyTrivia onChange={(e) => onEditChange(e)} />;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
}
