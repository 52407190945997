import React, { useState } from 'react';
// import {API_KEY, API_USER_ELEMENT_GAME_DATA} from '../components/constants/routes'
import { mains } from '../components/constants/routes';
const useMyTriviaList = (id, page) => {
    const [isLoading, setIsLoading] = useState(true);
    const [myTriviaQuestions, setMyTriviaQuestions] = useState([]);
    const [error, setError] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [mainSet, setMain] = useState(null)


    const getOnlyMine = (userId, trivialist) => {
        let myTrivia = trivialist.filter(item => item.data.author === userId)
        return myTrivia
    }


    React.useEffect(() => {
        const getCustomTriviaQuestion = async () => {
            let user = await localStorage.getItem("logged_in");
            let parsed = await JSON.parse(user);
           
            let main = await mains()
            setMain(main.API_KEY)
            

            const url =
            main.API_USER_ELEMENT_GAME_DATA+`/trivia-question-list?limit=${2000}`;
            const options = {
                method: "GET",
                headers: {
                    "api-key": main.API_KEY,
                    Accept: "application/json",
                    Authorization:
                        "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
                },
            };

            try {
                const response = await fetch(url, options);
                const data = await response.json();
                let myTriviaListsData = getOnlyMine(parsed.user.username, data.data)                
                setMyTriviaQuestions(myTriviaListsData);
                setIsLoading(false)
                setError(false)
                setTotalPages(data.total_pages)
                setCurrentPage(data.current_page)
                console.log(data)
                // console.log(data)
            } catch (error) {
                setIsLoading(false)
                console.error(error);
                setError(true)
            }
        };

        getCustomTriviaQuestion()
    }, [currentPage, mainSet])

    return { isLoading, myTriviaQuestions, error, totalPages, currentPage, setCurrentPage, setTotalPages };
};

export default useMyTriviaList;
