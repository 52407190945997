import React, { useEffect, useState } from "react";
import TriviaList from "../TriviaList/TriviaList";
import { Spin } from "antd";
import { useTrivia } from "../../contexts/TriviaContext";
import first from "../../assets/images/first2.png";
import TriviaListFeud from "../TriviaList/TriviaListFeud";
import { useFeud } from "../../contexts/FeudContext";
import {
  generationButtonCollection,
  renderCustomInput,
  renderGenerateAndAdd,
  renderHandleNextClick,
  renderPrivecy,
  renderRadioButtonCollection,
} from "../common/renderCollections";

const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};


export default function Index({
  createQuestionWithData,
  onNext,
  createCustomHandQuestions,
  gameTypeRef,
  gameTypeChangeData,
  privecyTypeRef,
  privecyTypeChangData,

}) {
  const [qAmmount] = useState(10);
  const [gameType, setGameType] = useState(
    gameTypeRef.current === "" ? "type_choice" : gameTypeRef.current
  );
  const [privecyType, setPrivecyType] = useState(privecyTypeRef.current != "" ? privecyTypeRef.current : "access_public")
  const [prompt, setPrompt] = useState("");
  const [promptFeud, setPromptFeud] = useState("");
  const endRef = React.useRef(null);
  const gameTypeChange = (e) => {
    setGameType(e.target.value);
    gameTypeChangeData(e.target.value);
  };

  const priveycTypeChange = (e) => {
    setPrivecyType(e.target.value);
    privecyTypeChangData(e.target.value);
  };

  const {
    handleGenerateTrivia,
    isLoading,
    triviaQuestionCache,
    updateQuestionInCache,
    updateGiveQuestionOptions,
    deleteGiveQuestionOptions,
    changeAnswere,
    deleteQuestion,
    openToggle,
    handleCustomInputOpen,
    handleCustomInputClose,
    handleCustomOptionRemove,
    onCustomQuestionAnswere,
    onCustomQuestionOptionData,
    handleCustomQuestionChange,
    handleCustomOptionAddition,
    handleCustomInputOpenAll,
    handleRemove,
    addtotriviacachecustom,
    handleCustomQuestionImageAdd,
    handleCustomQuestionImageRemove,
  } = useTrivia();

  const {
    handleGenerateFeud,
    updateQuestionInCacheFeud,
    updateGiveQuestionOptionFeud,
    deleteGiveQuestionOptionsFeud,
    changeAnswereFeud,
    deleteQuestionFeud,
    isLoadingFeud,
    feudQuestionCache,
    openToggleFeud,
    handleCustomInputOpenFeud,
    updateFeudQuestionsOptionPoint,
    handleCustomInputCloseFeud,
    handleCustomOptionRemoveFeud,
    onCustomQuestionAnswereFeud,
    onCustomQuestionOptionDataFeud,
    handleCustomQuestionChangeFeud,
    handleCustomOptionAdditionFeud,
    handleCustomInputOpenAllFeud,
    addtoFeudcachecustomFeud,
    handleCustomQuestionImageAddFeud,
    handleCustomQuestionImageRemoveFeud,
  } = useFeud();

  const createCustomHandQuestionsSunFather = (data) => {
    createCustomHandQuestions(data);
  };

  const scrollToBottom = () => {
    endRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleGenerateClick = async () => {
    if (prompt) {
      await handleGenerateTrivia(prompt, qAmmount);
      event({
        action: 'generate',
        category: 'trivia',
        label: 'Generate Trivia',
        value: prompt,
      });
      scrollToBottom();
    }
  };

  const handleGenerateFeudClick = async () => {
    if (promptFeud) {
      await handleGenerateFeud(promptFeud, qAmmount, "", gameType);
      event({
        action: 'generate',
        category: 'feud',
        label: 'Generate Feud',
        value: promptFeud,
      });
      scrollToBottom();
    }
  };

  const handleInputChange = (e) => {
    setPrompt({});
    setPrompt(e.target.value);
  };

  const handleInputChangeFeud = (e) => {
    setPromptFeud({});
    setPromptFeud(e.target.value);
  };

  const handleUploadToServer = async () => {
    onNext("type_choice");
    createQuestionWithData(
      triviaQuestionCache.filter((item) => item.question !== null)
    );
  };

  const handleUploadToServerFeud = async () => {
    onNext("type_feud");
    createQuestionWithData(feudQuestionCache);
  };

  useEffect(() => {
    setPrivecyType(
      privecyTypeRef.current != "" ? privecyTypeRef.current : "access_public"
    )
    setGameType(
      gameTypeRef.current !== "" ? gameTypeRef.current : "type_choice"
    );
  }, []);

  return (
    <div className=" flex flex-col justify-center items-center w-[100%]">
      <div className="flex flex-row  w-[100%] px-5 my-10">
        {renderCustomInput(
          gameType,
          prompt,
          handleInputChange,
          promptFeud,
          handleInputChangeFeud
        )}
        {generationButtonCollection(
          prompt,
          gameType,
          isLoading,
          first,
          isLoadingFeud,
          handleGenerateClick,
          handleGenerateFeudClick
        )}
      </div>
      <div className="w-[100%] border-b-[1px] border-black px-5 py-2 border-t-[1px]">{renderRadioButtonCollection(gameType, gameTypeChange)}</div>
      <div className="w-[100%] border-b-[1px] border-b-black px-5 py-2">{renderPrivecy(privecyType, priveycTypeChange)}</div>
      {isLoading && triviaQuestionCache.length < 0 ? (
        <div className="flex flex-row justify-center w-[59vw] shadow-md py-3 rounded-md">
          <Spin />
        </div>
      ) : (
        <div className="w-[100%]">
          {gameType === "type_choice" ? (
            <TriviaList
              onNext={onNext}
              openToggle={openToggle}
              updateQuestionInCache={updateQuestionInCache}
              updateGiveQuestionOptions={updateGiveQuestionOptions}
              deleteGiveQuestionOptions={deleteGiveQuestionOptions}
              deleteQuestion={deleteQuestion}
              handleCustomInputOpen={handleCustomInputOpen}
              changeAnswere={changeAnswere}
              handleCustomInputClose={handleCustomInputClose}
              handleCustomOptionRemove={handleCustomOptionRemove}
              onCustomQuestionAnswere={onCustomQuestionAnswere}
              onCustomQuestionOptionData={onCustomQuestionOptionData}
              handleCustomQuestionChange={handleCustomQuestionChange}
              handleCustomOptionAddition={handleCustomOptionAddition}
              createCustomHandQuestions={createCustomHandQuestionsSunFather}
              handleCustomInputOpenAll={handleCustomInputOpenAll}
              handleRemove={handleRemove}
              handleCustomQuestionImageAdd={handleCustomQuestionImageAdd}
              addtotriviacachecustom={addtotriviacachecustom}
              handleCustomQuestionImageRemove={handleCustomQuestionImageRemove}
              triviaQuestions={
                triviaQuestionCache ? triviaQuestionCache : []
              }
            />
          ) : (
            <TriviaListFeud
              onNext={onNext}
              openToggle={openToggleFeud}
              updateQuestionInCache={updateQuestionInCacheFeud}
              updateGiveQuestionOptionFeud={updateGiveQuestionOptionFeud}
              updateFeudQuestionsOptionPoint={updateFeudQuestionsOptionPoint}
              deleteGiveQuestionOptionsFeud={deleteGiveQuestionOptionsFeud}
              deleteQuestion={deleteQuestionFeud}
              handleCustomInputOpen={handleCustomInputOpenFeud}
              changeAnswere={changeAnswereFeud}
              handleCustomInputClose={handleCustomInputCloseFeud}
              handleCustomOptionRemove={handleCustomOptionRemoveFeud}
              onCustomQuestionAnswere={onCustomQuestionAnswereFeud}
              onCustomQuestionOptionData={onCustomQuestionOptionDataFeud}
              handleCustomQuestionChange={handleCustomQuestionChangeFeud}
              handleCustomOptionAddition={handleCustomOptionAdditionFeud}
              createCustomHandQuestions={createCustomHandQuestionsSunFather}
              handleCustomInputOpenAll={handleCustomInputOpenAllFeud}
              handleRemove={handleRemove}
              handleCustomQuestionImageAdd={handleCustomQuestionImageAddFeud}
              addtotriviacachecustom={addtoFeudcachecustomFeud}
              handleCustomQuestionImageRemove={
                handleCustomQuestionImageRemoveFeud
              }
              triviaQuestions={
                feudQuestionCache ? feudQuestionCache : []
              }
            />
          )}
        </div>
      )}
      {renderGenerateAndAdd(
        gameType,
        endRef,
        addtotriviacachecustom,
        isLoading,
        handleGenerateClick,
        isLoadingFeud,
        addtoFeudcachecustomFeud,
        handleGenerateFeudClick
      )}
      {renderHandleNextClick(
        gameType,
        triviaQuestionCache,
        handleUploadToServer,
        feudQuestionCache,
        handleUploadToServerFeud
      )}
      <div ref={endRef}></div>
    </div>
  );
}
